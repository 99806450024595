'use client';

import { useSearchParams } from 'next/navigation';
import { AssociationsBanner } from '@/src/components/Common/AssociationsBanner/AssociationsBanner';
import { LoginViewHeaderType } from '../LoginView/LoginView';
import { AuthDrawerContainer } from '../components/AuthDrawerContainer';
import { AuthFormHeader } from '../components/AuthFormHeader';
import { AuthPageContainer } from '../components/AuthPageContainer';
import { SignupForm } from './SignupForm';
type SignupViewProps = {
  viewType: 'drawer' | 'page';
  headerTextsType?: LoginViewHeaderType;
  userType?: {
    isBuyer: boolean;
    isSupplier: boolean;
    isManufacturer: boolean;
  };
};
export const SignupView = ({
  viewType,
  userType,
  headerTextsType
}: SignupViewProps) => {
  const searchParams = useSearchParams();
  const {
    isBuyer,
    isSupplier,
    isManufacturer
  } = userType ?? {};
  const finalHeaderTextType = (() => {
    if (searchParams.get('headerTextsType')) {
      return (searchParams.get('headerTextsType') as SignupViewProps['headerTextsType']);
    }
    if (headerTextsType) {
      return headerTextsType;
    }
    return undefined;
  })();
  const {
    title,
    subtitle
  } = getTexts(finalHeaderTextType);
  if (viewType === 'drawer') {
    return <AuthDrawerContainer>
        <AuthFormHeader title={title} subtitle={subtitle} />
        <SignupForm isBuyer={isBuyer} isSupplier={isSupplier} isManufacturer={isManufacturer} />
        <AssociationsBanner containerSx={{
        py: 4
      }} titleSx={{
        typography: 'h5'
      }} logosHeight={40} />
      </AuthDrawerContainer>;
  }
  return <AuthPageContainer outsideFormContent={<AssociationsBanner />} data-sentry-element="AuthPageContainer" data-sentry-component="SignupView" data-sentry-source-file="SignupView.tsx">
      <AuthFormHeader title={title} subtitle={subtitle} data-sentry-element="AuthFormHeader" data-sentry-source-file="SignupView.tsx" />
      <SignupForm shouldRedirect data-sentry-element="SignupForm" data-sentry-source-file="SignupView.tsx" />
    </AuthPageContainer>;
};
const getTexts = (textsType: SignupViewProps['headerTextsType']) => {
  switch (textsType) {
    case 'fromCart':
      return {
        title: 'Just a little more to go!',
        subtitle: 'By creating your own account you will have access to exclusive offers and discounts, seamless order tracking and history, and&nbsp;faster checkout process.'
      };
    case 'fromPartPage':
      return {
        title: 'Just a little more to go!',
        subtitle: 'Create a Quotebeam account to get an access to exclusive offers and discounts, seamless order tracking history and a faster checkout process.'
      };
    default:
      return {
        title: 'Welcome to Quotebeam',
        subtitle: 'Create a Quotebeam account to get an access to exclusive offers and&nbsp;discounts, seamless order tracking history and a&nbsp;faster checkout process.'
      };
  }
};