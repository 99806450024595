import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useSearchParams, usePathname, useRouter } from 'next/navigation';
import { z } from 'zod';
import { recordEvent, setGA4CurrentUser } from '@qb/frontend/analytics/ga4';
import { postRequestPromise } from '@qb/httpRequest/RequestPromise';
import { WebAPIFullURL, SERVER_ERROR_MESSAGE } from '@qb/httpRequest/constants';
import { isApiBusinessLogicError } from '@qb/httpRequest/utils';
import { CreateUserCompanyPairResponse, CreateUserCompanyPairPayload } from '@/shared/types/controllers/UserControllerTypes';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
import { setUserAfterSignup } from '@/src/store/reducers/main';
import { CreateQuoteIfRecentlyStored } from '../util';
const PASSWORD_MIN_LENGTH = 6;
type SignupRequest = CreateUserCompanyPairPayload & {
  userInviteToken?: string;
  pendingUserToken?: string;
};
export type Fields = {
  areTermsAccepted: boolean;
} & SignupRequest;
const schema = z.object({
  areTermsAccepted: z.boolean({
    required_error: 'Terms and conditions must be accepted.'
  }),
  firstName: z.string({
    required_error: 'First Name is required.'
  }),
  lastName: z.string({
    required_error: 'Last Name is required.'
  }),
  email: z.string({
    required_error: 'Email is required.'
  }).email('Email must be valid'),
  companyName: z.string({
    required_error: 'Company Name is required.'
  }),
  password: z.string({
    required_error: 'Password is required.'
  }).min(PASSWORD_MIN_LENGTH, `Password must be at least ${PASSWORD_MIN_LENGTH} characters.`)
});
export function postSignupRequest(payload: SignupRequest, endpointUrlOverride?: string): Promise<void | CreateUserCompanyPairResponse> {
  return postRequestPromise(endpointUrlOverride || `${WebAPIFullURL}/createUserCompanyPair`, payload);
}
export const useSignupRequest = (handleExistingEmailError?: () => void) => {
  const dispatch = useDispatch();
  const {
    hideDrawer
  } = useRightDrawerContext();
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const [errorMessage, setErrorMessage] = useState('');
  const submitSignupRequest = async (fields: Fields, endpointUrlOverride?: string) => {
    setErrorMessage('');
    const data = schema.safeParse(fields);
    if (!data.success) {
      const formatted = data.error.errors.map(err => err.message).join(', ');
      setErrorMessage(formatted);
      return;
    }
    if (!(fields.isSupplier || fields.isBuyer || fields.isManufacturer)) {
      setErrorMessage('Select at least one role');
      return;
    }
    try {
      const data = await postSignupRequest(fields, endpointUrlOverride);
      if (data && typeof data.user === 'object') {
        dispatch(setUserAfterSignup(data.user));
        hideDrawer();
        setGA4CurrentUser(data.user);
        recordEvent('sign_up');
        recordEvent('login');
        const redirectURL = getRedirectURL(pathname, searchParams.get('headerTextsType'));
        if (redirectURL) {
          router.push(redirectURL);
        }
        CreateQuoteIfRecentlyStored((err: Error, quoteData: unknown) => {
          if (err) {
            console.error(err);
          }
          if (quoteData) {
            toast.success('Quote created successfully');
          }
          const event = new Event('ReloadQuotebeamWidget');
          window.dispatchEvent(event);
          window?.salesPilotPostMessages?.notifyUserHasSignedUp();
        });
      } else {
        toast.error(SERVER_ERROR_MESSAGE);
      }
    } catch (e) {
      if (isApiBusinessLogicError(e)) {
        if (e.code === 'EXISTING_EMAIL') {
          handleExistingEmailError?.();
          return;
        }
        setErrorMessage(e.message);
      }
    }
  };
  return {
    submitSignupRequest,
    errorMessage
  };
};
const getRedirectURL = (pathname: string, headerTextsType: string | null) => {
  const isOriginallyFromPartPage = pathname.startsWith('/part/') || headerTextsType === 'fromPartPage';
  const isOriginallyFromCart = pathname.startsWith('/cart') || headerTextsType === 'fromCart';
  if (isOriginallyFromPartPage || isOriginallyFromCart) {
    return '/cart/checkout';
  }
  return '/onboarding';
};