import { PostRequest } from '@qb/httpRequest/Request';
import { WebAPIFullURL } from '@qb/httpRequest/constants';

export const PART_LOCAL_STORAGE_PREFIX = 'part_';

export const CreateQuoteIfRecentlyStored = (done) => {
  if (!localStorage) return done();

  let createQuoteParams = null;
  let mostRecentTimestamp = 0;
  for (let i = 0; i < localStorage.length; i++) {
    let key = localStorage.key(i);
    if (key.startsWith(PART_LOCAL_STORAGE_PREFIX)) {
      let values = JSON.parse(localStorage.getItem(key));
      // 3 minutes in milliseconds.
      // Get only the most recent
      // custom part revision can't be viewed publicly, so we'll only check that part is set
      if (
        values.timestamp > Date.now() - 3 * 60 * 1000 &&
        values.timestamp > mostRecentTimestamp &&
        values.part
      ) {
        createQuoteParams = values;
        mostRecentTimestamp = values.timestamp;
      }
    }
  }
  // Create Quote if we can
  if (createQuoteParams) {
    const url = `${WebAPIFullURL}/quote/createMultipleWithPart`;
    const requestData = {
      qty: createQuoteParams.qty,
      supplierIDs: createQuoteParams.supplierIDs,
      message: createQuoteParams.message,
      part: createQuoteParams.part,
    };
    PostRequest(
      url,
      requestData,
      (data) => {
        localStorage.removeItem(
          `${PART_LOCAL_STORAGE_PREFIX}${createQuoteParams.part}`,
        );
        return done(null, data);
      },
      () => {
        return done();
      },
    );
  } else {
    return done();
  }
};
